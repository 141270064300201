<script setup lang='ts'>
import { imageTransform, swiperPath } from '@qcwp/common'
import { LogicConfig } from '~/logic/common/config'
import type { SwiperItem } from '~~/src/logic/swiper'
import type { AD_KEYS, AD_POSITION } from '~~/src/server/modules/ad/types'
import { getAdComponentData } from '~~/src/store'

const props = withDefaults(defineProps<{
  list?: SwiperItem[]
  adKey?: AD_KEYS
  adPosition?: AD_POSITION
  positionKey?: string
}>(), {
  list: () => [],
})
const { adData } = getAdComponentData(props.adKey, props.adPosition)
const { isMobile } = useDevice()
const width = isMobile ? 620 : 930
const height = Math.floor(width * 0.577)

const { getConfig } = LogicConfig()

const isAutoPlay = ref(true)
if (props.positionKey) {
  const swiperConfig = (await getConfig('qcwp.swiper.carousel')) as Record<string, 0 | 1>
  // console.log('getConfig', swiperConfig)
  try {
    isAutoPlay.value = !(swiperConfig?.[props.positionKey] === 0)
  }
  catch (error) {
    console.error(error)
  }
}
</script>

<template>
  <base-proportion class="carousel-container" :proportion="56.25">
    <n-carousel
      draggable
      :show-arrow="props.list.length > 1"
      :default-index="0"
      :autoplay="isAutoPlay"
      dot-type="line"
      effect="slide"
      class="carousel"
    >
      <template v-if="adData">
        <NuxtLink
          v-for="ad in adData" :key="ad.adLink"
          :to="ad.adLink" class="relative block w-full h-full bg-hex-F3F4F6"
          target="_blank"
        >
          <base-img
            class="carousel-img w-full h-full object-cover"
            preview-disabled
            :alt="ad.adTitle"
            :lazy="false"
            :src="imageTransform(getPlatform(ad), { width, height, format: 'webp' })"
          />
          <div class="carousel-title">
            {{ ad.adTitle }}
          </div>
        </NuxtLink>
      </template>
      <NuxtLink
        v-for="item, index in props.list" :key="index"
        :to="swiperPath(item.type, item)"
        class="relative block w-full h-full bg-hex-F3F4F6"
      >
        <base-img
          class="carousel-img object-cover w-full h-full"
          preview-disabled
          :alt="item.title"
          :lazy="false"
          :src="imageTransform(item.img, {
            width,
            height,
            format: 'webp',
            waterMark: item.watermark,
          })"
        />
        <div class="carousel-title">
          {{ item.title }}
        </div>
      </NuxtLink>
    </n-carousel>
  </base-proportion>
</template>

<style lang="scss" scoped>
.carousel {
  &-container {
    --uno: w-full rounded-md overflow-hidden;
  }

  &-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &-title {
    --uno: absolute bottom-0 left-0 w-full h-10 leading-10  px-4 text-4 truncate;
    --uno: bg-gradient-to-t from-gray-900 text-white;
  }
}

.n-carousel.n-carousel--bottom :deep(.n-carousel__arrow-group) {
  --uno: bottom-12.5 hidden md:flex;
}

.n-carousel.n-carousel--bottom :deep(.n-carousel__dots) {
  --uno: bottom-12 hidden md:flex;

  // &.n-carousel__dots--line .n-carousel__dot {
  //   width: 3px;
  //   margin: 2px;
  //   border-radius: 0;

  //   &.n-carousel__dot--active {
  //     width: 10px;
  //   }
  // }
}
</style>
