import { useCommonStore } from '~~/src/store'
import { getConfigKey } from '~~/src/server/modules/base'

export function LogicConfig() {
  const commonStore = useCommonStore()
  const { CONFIG_MAPS } = commonStore

  /**
   * 获取字典
   * setup中使用, 否则可能会nuxt instance undefined.  OR use callWithNuxt
   * @param dict 字典类型
   */
  async function getConfig(configKey: string) {
    if (!CONFIG_MAPS.has(configKey) || !CONFIG_MAPS.get(configKey)) {
      const asyncData = await useAsyncData(`CONFIG_${configKey}`, () => getConfigKey(configKey))
      const { data } = useNuxtAsyncHandle(asyncData)
      setConfig(configKey, data?.configValue)
    }

    return CONFIG_MAPS.get(configKey)
  }

  function setConfig(configKey: string, data: any) {
    if (configKey === 'qcwp.swiper.carousel' && data) {
      try {
        data = JSON.parse(data)
      }
      catch (e) {
        console.error(e)
        data = JSON.parse(data)
      }
    }
    CONFIG_MAPS.set(configKey, data)
  }

  return {
    getConfig,
    setConfig,
  }
}
